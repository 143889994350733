

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import { ToastContainer, toast } from "react-toastify";
import { baseurl } from "../../constants";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import Loading from "../Loading/Loading";
import "./ProductList.css";

const ProductListing = () => {
  const { cat_name } = useParams();
  if (!cat_name) {
    window.location.href = '/';
  }

  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState({});
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    fetch(`${baseurl}product/get-category/${cat_name}`)
      .then((response) => response.json())
      .then((res) => {
        setSelectedCategory({ ...res.data });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [cat_name]);

  const addToCartMessage = () => {
    toast.success("Product Successfully Added To Cart!", {
      position: "bottom-right",
      autoClose: 5000,
    });
  };

  if (loading) return <Loading />;

  if (!selectedCategory) {
    return <div>Category not found</div>;
  }

  return (
    <div className="container">
      <ToastContainer />
      <h2 className="category-title">{selectedCategory.name}</h2>
      <Breadcrumb />

      {/* Products without subcategory */}
      {selectedCategory.products && selectedCategory.products.length > 0 && (
        <div className="subcategory-section">
          <h3 className="subcategory-title">All Products</h3>
          <div className="product-grid">
            {selectedCategory.products.map((product) => (
              <Link
                to={`/products/${cat_name}/${product.name}`}
                key={product.id}
                className="product-card"
              >
                <img
                  src={baseurl + product.image}
                  alt={product.name}
                  className="product-image"
                />
                <h4 className="product-name">{product.name}</h4>
                <p className="product-desc">{product.description}</p>
                <button
                  className="order-now-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addToCart(product);
                    addToCartMessage();
                  }}
                >
                  Add to Cart
                </button>
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Subcategories with their products */}
      {selectedCategory.sub_catogeries && 
        selectedCategory.sub_catogeries.map((subcat, subcatIndex) => (
          subcat.products && subcat.products.length > 0 && (
            <div key={subcatIndex} className="subcategory-section">
              <h3 className="subcategory-title">{subcat.name}</h3>
              <div className="product-grid">
                {subcat.products.map((product) => (
                  <Link
                    to={`/products/${cat_name}/${product.name}`}
                    key={product.id}
                    className="product-card"
                  >
                    <img
                      src={baseurl + product.image}
                      alt={product.name}
                      className="product-image"
                    />
                    <h4 className="product-name">{product.name}</h4>
                    <p className="product-desc">{product.description}</p>
                    <button
                      className="order-now-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addToCart(product);
                        addToCartMessage();
                      }}
                    >
                      Add to Cart
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          )
        ))
      }

      {/* Fallback if no products exist */}
      {(!selectedCategory.products || selectedCategory.products.length === 0) && 
       (!selectedCategory.sub_catogeries || selectedCategory.sub_catogeries.length === 0) && (
        <p>No products available in this category.</p>
      )}
    </div>
  );
};

export default ProductListing;